import * as bootstrap from 'bootstrap';

document.addEventListener('DOMContentLoaded', () => {
    const galleryModal = new bootstrap.Modal(document.getElementById('galleryModal'));

    document.querySelectorAll('.gallery-item').forEach(item => {
        item.addEventListener('click', () => {
            let fullSizeUrl = item.querySelector('img.fullsize').src;
            document.getElementById('gallery-full-size').src = '';
            document.getElementById('gallery-full-size').src = fullSizeUrl;
            galleryModal.show();
        });
    });

    const estimateModal = new bootstrap.Modal(document.getElementById('estimateModal'));

    document.getElementById('showEstimateBtn').addEventListener('click', () => {
        // Reset the form
        document.querySelectorAll('#estimateForm input, #estimateForm textarea').forEach(input => {
            input.value = '';
        });
        document.querySelectorAll('#estimateForm select').forEach(select => {
            select.selectedIndex = 0;
        });
        document.getElementById('estimateForm').classList.remove('was-validated');
        document.getElementById('estimateSpinner').classList.add('d-none');
        document.getElementById('estimateError').classList.add('d-none');

        // Show the modal
        estimateModal.show();
    });

    const estimateConfirmationModal = new bootstrap.Modal(document.getElementById('estimateConfirmationModal'));

    document.getElementById('estimateSubmit').addEventListener('click', () => {
        document.getElementById('estimateForm').requestSubmit();
    });
    document.getElementById('estimateForm').addEventListener('submit', (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = document.getElementById('estimateForm');
        // Check if the form is valid
        if (form.checkValidity()) {
            grecaptcha.ready(function() {
                grecaptcha.execute('6LeiNu8pAAAAACmbfXYGEq-LHUnlzdSW59kyvfVj', {action: 'submit'}).then(function(token) {
                    const body = {
                        name: document.getElementById('customerName').value,
                        email: document.getElementById('customerEmail').value,
                        phone: document.getElementById('customerPhone').value,
                        make: document.getElementById('carMake').value,
                        model: document.getElementById('carModel').value,
                        service: document.getElementById('estimateService').value,
                        message: document.getElementById('estimateMessage').value,
                        token,
                    };
                    fetch('https://zzhopleleg.execute-api.us-west-1.amazonaws.com/Prod/estimate', { method: 'POST', body: JSON.stringify(body) })
                        .then(() => {
                            estimateModal.hide();
                            estimateConfirmationModal.show();
                        })
                        .catch(error => {
                            console.log(error);
                            document.getElementById('estimateError').classList.remove('d-none');
                            document.getElementById('estimateSpinner').classList.add('d-none');
                        });
        
                    document.getElementById('estimateSpinner').classList.remove('d-none');
                });
            });
        }

        form.classList.add('was-validated');
    });
});